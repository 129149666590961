import { useRouter } from 'next/router'
import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import type { UrlObject } from 'url'
import { setQueryPage } from './parsePagination'

export type PaginatedUrl = number | string | UrlObject

export type NextPageInViewProps = {
	page: PaginatedUrl
	children?: React.ReactNode
	keepPreviousUrl?: boolean
}

const NextPageInViewInner: FunctionComponent<NextPageInViewProps> = ({
	page,
	children,
	keepPreviousUrl,
}) => {
	const router = useRouter()

	const routerRef = useRef(router)

	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin: '0px 0px 360px 0px',
	})

	const pageString = JSON.stringify(routerRef.current.query.page)

	useEffect(() => {
		if (inView) {
			const oldPath = routerRef.current.asPath
			routerRef.current
				.replace(
					typeof page === 'number'
						? {
								query: {
									page: setQueryPage(routerRef.current.query.page, page),
								},
						  }
						: page,
					undefined,
					{ scroll: false }
				)
				.then(() => {
					if (keepPreviousUrl) {
						routerRef.current.replace(oldPath, undefined, { scroll: false, shallow: true })
					}
				})
		}
	}, [page, inView, pageString, keepPreviousUrl])

	return (
		<div ref={ref} style={{ minHeight: 1 }}>
			{children}
		</div>
	)
}

export const NextPageInView: FunctionComponent<NextPageInViewProps> = (props) => {
	return <NextPageInViewInner key={JSON.stringify(props.page)} {...props} />
}
