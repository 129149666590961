import { RichTextRenderer } from '@contember/react-client'
import React from 'react'
import type { CoverResult } from '../data/content/CoverFragment'
import s from './Cover.module.sass'

export type CoverProps = {
	cover: CoverResult
}

export function Cover(props: CoverProps) {
	return (
		<div className={s.Cover}>
			<span className={s.Title}>
				<RichTextRenderer source={props.cover.title ?? null} />
			</span>
			<a href={'mailto:' + props.cover.email} className={s.Email}>
				{props.cover.email}
			</a>
		</div>
	)
}
