import clsx from 'clsx'
import Link from 'next/link'
import { useState } from 'react'
import type { LinkListResult } from '../data/content/LinkListFragment'
import { Close } from '../svg/icons'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utils/contemberLinkToHref'
import s from './Header.module.sass'

export type HeaderProps = {
	links: LinkListResult
}

export function Header(props: HeaderProps) {
	const [open, setOpen] = useState(false)

	return (
		<div className={s.container}>
			<div className={clsx(s.wrapper, open && s.wrapperIsOpen)}>
				{props.links.items.length > 0 && (
					<button className={clsx(s.menuBtn, open && s.menuOpen)} onClick={() => setOpen(true)}>
						<div className={s.moreBtn}>More</div>
					</button>
				)}
				{props.links.items.length > 0 && (
					<button className={clsx(s.menuBtn, !open && s.menuClose)} onClick={() => setOpen(false)}>
						<div className={s.closeBtn}>
							<Close />
						</div>
					</button>
				)}
				<div className={clsx(s.links, open && s.isOpen)}>
					{props.links.items.map((link) => (
						<div key={link.id} className={s.linkWrapper}>
							{link.link && (
								<div className={s.link}>
									<Link {...contemberLinkToHrefTargetRel(link.link)}>
										{link.link.title ?? contemberLinkToHref(link.link)}
									</Link>
								</div>
							)}
							{link.text && <div className={s.linkText}>{link.text}</div>}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
