function SvgClose() {
	return (
		<svg width="39" height="39" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x=".95" y="1" width="37.03" height="37.03" rx="18.52" fill="#fff" />
			<path stroke="#000" strokeWidth="2" d="M11.77 11.83 26.68 27.5M26.8 11.83 11.9 27.5" />
			<rect x=".95" y="1" width="37.03" height="37.03" rx="18.52" stroke="#000" />
		</svg>
	)
}

export default SvgClose
