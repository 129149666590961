import type { LinkResult } from '../data/content/LinkFragment'
import s from './Footer.module.sass'

export type FooterProps = {
	link: LinkResult
}

export function Footer(props: FooterProps) {
	return (
		<a className={s.Mangoweb} href={props.link.internalLink?.url ?? props.link.externalLink}>
			{props.link.title}
		</a>
	)
}
