export const parsePagination = (value: string) => {
	const match = value.match(/^([0-9]+)$/)

	if (match) {
		return { page: Number(match[1]) }
	}

	return false
}

export const setQueryPage = (parts: string | string[] | undefined, page: number) => {
	parts = Array.isArray(parts) ? parts : typeof parts === 'string' ? [parts] : []

	const info = parseQueryPage(parts)

	if (page <= 1) {
		return info.parts
	}

	return [...info.parts, `${page}`]
}

export const parseQueryPage = (parts: string[]) => {
	parts = [...parts]
	const pagination = parsePagination(parts[parts.length - 1] ?? '')

	let page: null | number = null

	if (pagination) {
		page = pagination.page
		parts.pop()
	}

	return {
		parts,
		page,
	}
}
