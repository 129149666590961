import type { ReferenceRendererProps } from '@contember/react-client'
import React from 'react'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import { ContemberImage } from '../../ContemberImage'
import s from './Image.module.sass'

export function Image({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div className={s.Image}>
			{reference.image && (
				<ContemberImage image={reference.image} layout="intrinsic" objectFit="cover" />
			)}
			<p className={s.Note}>{reference.primaryText}</p>
		</div>
	)
}
