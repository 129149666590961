import type { HomePageResult } from '../data/content/HomePageFragment'
import { Cover } from './Cover'
import s from './HomePage.module.sass'
import { Project } from './Project'

export type HomeProps = {
	homePage: HomePageResult
}

export function HomePage(props: HomeProps) {
	return (
		<div className={s.HomePage}>
			{props.homePage.cover && <Cover cover={props.homePage.cover} />}
			<div className={s.Projects}>
				{props.homePage.projects.map((project, id) => (
					<Project key={id} project={project} />
				))}
			</div>
		</div>
	)
}
