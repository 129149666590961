import type { ReferenceRendererProps } from '@contember/react-client'
import type { ContentBlockReference } from '../../../data/content/ContentFragment'
import s from './TwitterEmbed.module.sass'

export function TwitterEmbed({ reference }: ReferenceRendererProps<ContentBlockReference>) {
	return (
		<div className={s.TwitterEmbed}>
			<div className={s.Embed} dangerouslySetInnerHTML={{ __html: `${reference.primaryText}` }} />
		</div>
	)
}
