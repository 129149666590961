import React from 'react'
import type { ArticleResult } from '../data/content/ArticleFragment'
import { NextPageInView } from '../utils/paginated/NexPageInView'
import { useKeepOld } from '../utils/paginated/usePaginated'
import ActiveUrlInView from './ActiveUrlInView'
import s from './Article.module.sass'
import { BlockRenderer } from './blocks/BlockRenderer'

export type ArticleProps = {
	article: ArticleResult
	nextPageUrl?: string | null
}

export const Article = React.memo((props: ArticleProps) => {
	return (
		<div className={s.Article}>
			<div key={props.article.id}>
				<h1 className={s.Title}>{props.article.title}</h1>
				<div className={s.Date}>
					{new Intl.DateTimeFormat('cs', {
						day: 'numeric',
						month: 'long',
						year: 'numeric',
					}).format(new Date(props.article.publishedAt))}
				</div>
				{props.article.html ? (
					<div className={s.Html} dangerouslySetInnerHTML={{ __html: props.article.html }}></div>
				) : (
					<BlockRenderer blocks={props.article.content?.blocks ?? []} />
				)}
			</div>
		</div>
	)
})

Article.displayName = 'Article'

export function InfiniteArticles(props: ArticleProps) {
	const { items } = useKeepOld({ id: props.article.id, ...props })

	return (
		<>
			{items.map((article) => (
				<ActiveUrlInView key={article.id} url={props.article.link?.url ?? ''}>
					<Article {...article} />
				</ActiveUrlInView>
			))}
			{props.nextPageUrl && <NextPageInView page={props.nextPageUrl} keepPreviousUrl />}
		</>
	)
}
