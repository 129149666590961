import { useEffect, useMemo, useState } from 'react'
import type { Paginated } from './types'

export function usePaginated<Item>(current: Paginated<Item>) {
	const [pages, setPages] = useState([current])

	useEffect(() => {
		setPages((old) => {
			const found = old.find((paged) => paged.page === current.page)
			if (!found) {
				const updated = [...old, current]
				updated.sort((a, b) => a.page - b.page)
				return updated
			}
			return old
		})
	}, [current, current.page])

	const items = useMemo(() => {
		return pages.flatMap((page) => page.items)
	}, [pages])

	return {
		current: pages[pages.length - 1],
		pages,
		items,
	}
}

export function useKeepOld<Item extends { id: string }>(current: Item) {
	const [items, setItems] = useState([current])

	useEffect(() => {
		setItems((old) => {
			const found = old.find((paged) => paged.id === current.id)
			if (!found) {
				const updated = [...old, current]
				return updated
			}
			return old
		})
	}, [current, current.id])

	return {
		current: items[items.length - 1],
		items,
	}
}
