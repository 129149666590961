/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import type { ProjectResult } from '../data/content/ProjectFragment'
import s from './Project.module.sass'

export type ProjectProps = {
	project: ProjectResult
}

export function Project(props: ProjectProps) {
	const [open, setisOpen] = React.useState(false)
	const whiteText = props.project.whiteText ? [s.WhiteText] : []

	return (
		<div
			className={clsx(s.Project, open && s.isOpen)}
			onClick={() => {
				setisOpen((open) => {
					return !open
				})
				// window.location.hash = String(props.project.name)
			}}>
			<div className={s.Logo}>
				{props.project.logo?.url && (
					<Image alt="" src={props.project.logo?.url} layout="fill" objectFit="cover" />
				)}
			</div>
			<p className={clsx(s.Text, s.Position)}>{props.project.position}</p>
			<p className={s.Name}>{props.project.name}</p>
			<p className={s.Year}>{props.project.year}</p>
			<div
				// id={props.project.name}
				className={clsx(s.InfoBox, open && s.isOpen, whiteText)}
				style={{
					backgroundColor: props.project.labelColor?.colorHex,
				}}>
				<div className={s.Close}>close</div>
				{props.project.image?.url && (
					<div
						className={s.Image}
						style={
							{
								'--projectImage-aspectRatio':
									(props.project.image.width ?? 1) / (props.project.image.height ?? 1),
							} as React.CSSProperties
						}>
						{props.project.image?.url && (
							<Image
								alt=""
								src={props.project.image?.url}
								layout="fill"
								objectFit="cover"
								sizes={['(min-width: 1536px) 302px ', '100vw'].join()}
								priority
							/>
						)}
					</div>
				)}
				<div className={clsx(s.Text, s.Description)}>{props.project.description}</div>
				{props.project.leadParagraph && (
					<div className={clsx(s.Text, s.LeadParagraph)}>
						<ReactMarkdown>{props.project.leadParagraph}</ReactMarkdown>
					</div>
				)}
				<a href={props.project.url} className={clsx(s.Name, whiteText)}>
					{props.project.name}
				</a>
				<p className={clsx(s.Year, whiteText)}>{props.project.year}</p>
			</div>
		</div>
	)
}
