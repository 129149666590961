import * as React from 'react'

function SvgGo() {
	return (
		<svg width="1em" height="1em" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
			<g stroke="currentColor" strokeWidth={10} fill="none" fillRule="evenodd">
				<path d="M486.519 256c0-26.316 13.158-53.947 5.263-77.632-7.895-25-34.21-39.473-50-59.21-14.474-21.053-21.053-51.316-40.79-65.79-19.736-14.473-50-10.526-75-18.42C303.625 27.052 281.257 6 256.257 6c-26.316 0-48.684 21.053-71.053 28.947-23.684 7.895-53.947 3.948-75 18.421-19.737 14.474-26.316 44.737-40.79 65.79-14.473 19.737-42.105 35.526-50 59.21-7.894 23.685 5.264 51.316 5.264 77.632 0 26.316-13.158 53.947-5.263 77.632 7.894 23.684 34.21 39.473 50 59.21 14.473 21.053 21.052 51.316 40.79 65.79 19.736 14.473 50 10.526 75 18.42 23.681 7.896 46.05 28.948 71.05 28.948 26.316 0 48.684-21.053 71.052-28.947 23.685-7.895 53.948-3.948 75-18.421 19.737-14.474 26.316-44.737 40.79-65.79 14.474-19.737 42.105-35.526 50-59.21 6.579-23.685-6.58-51.316-6.58-77.632" />
				<path
					d="M220.73 166.526c0-11.842-3.948-22.368-10.527-30.263a43.421 43.421 0 00-32.895-15.79c-24.918.713-44.747 21.124-44.736 46.053v185.527c0 25 19.736 46.052 44.736 46.052 25 0 44.737-21.052 44.737-46.052V274.42s1.316-9.21-6.579-18.421c-6.579-6.579-17.105-7.895-17.105-7.895h-31.579"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M290.466 212.579c0-25 19.737-46.053 44.737-46.053 25 0 44.737 21.053 44.737 46.053v139.474c0 25-19.737 46.052-44.737 46.052-24.919-.712-44.747-21.123-44.737-46.052V212.579h0z"
					strokeLinecap="square"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	)
}

export default SvgGo
