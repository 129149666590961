/* eslint-disable @next/next/next-script-for-ga */
import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { InfiniteArticles } from '../app/components/Article'
import { Footer } from '../app/components/Footer'
import { Header } from '../app/components/Header'
import { HomePage } from '../app/components/HomePage'
import { SeoHead } from '../app/components/SeoHead'
import { combineUrl } from '../app/data/combineUrl'
import { ArticleFragment } from '../app/data/content/ArticleFragment'
import { ArticlePageFragment } from '../app/data/content/ArticlePageFragment'
import { HomePageFragment } from '../app/data/content/HomePageFragment'
import { LinkFragment } from '../app/data/content/LinkFragment'
import { LinkListFragment } from '../app/data/content/LinkListFragment'
import { normalizePaginated } from '../app/utils/paginated/normalizePaginated'
import { One, OrderDirection, Thunder } from '../generated/content'
import {
	RespondWithNotFound,
	RespondWithRedirect,
	serverPropsHandler,
} from '../packages/@necktip/next/api/handlers'
import { getZeusConnection } from '../packages/@necktip/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	const { article, articlePage, homePage } = props.data.getLinkable ?? {}

	const headerLinks = props.data.getHeader?.links
	const footerLink = props.data.getFooter?.link

	return (
		<div>
			<Head>
				<title>Vojta Rocek</title>
				<link rel="icon" href="/favicon.ico" />
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#ffffff" />
				{/* Global site tag (gtag.js) - Google Analytics */}
				<script async src="https://www.googletagmanager.com/gtag/js?id=UA-42394723-1"></script>
				<script
					dangerouslySetInnerHTML={{
						__html: `
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', 'UA-42394723-1');
				`,
					}}
				/>
			</Head>
			<SeoHead seo={homePage?.seo && articlePage?.seo} />
			{headerLinks && <Header links={headerLinks} />}
			{article && <InfiniteArticles article={article} nextPageUrl={props.nextPageUrl} />}
			{homePage && <HomePage homePage={homePage} />}
			{footerLink && <Footer link={footerLink} />}

			{/* <section>
				<h1>
					Props from <code>getServerSideProps</code>
				</h1>
				<Dump data={props} />
			</section>

			<section>
				<h1>
					Server-side number sum with <code>/api/v1/[handler]</code>
				</h1>
				<SumForm />
			</section> */}
		</div>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const urlInfo = combineUrl(context)

	const url = `${urlInfo.path}`

	const perPage = 1
	const page = Math.max(1, Number(urlInfo.page ?? 1))

	const data = await content.query({
		// ...SiteFragment(urlInfo.locale),
		listArticle: [{ orderBy: [{ publishedAt: OrderDirection.desc }] }, ArticleFragment()],
		getHeader: [{ by: { unique: One.One } }, { links: [{}, LinkListFragment()] }],
		getLinkable: [
			{ by: { url } },
			{
				id: true,
				url: true,
				homePage: [{}, HomePageFragment()],
				article: [{}, ArticleFragment()],
				articlePage: [{}, ArticlePageFragment()],
				linkItem: [{}, { link: [{}, LinkFragment()] }],
			},
		],
		getFooter: [
			{
				by: { unique: One.One },
			},
			{
				link: [{}, LinkFragment()],
			},
		],
	})

	if (data.getLinkable?.articlePage) {
		const latestArticle = await content.query({
			listArticle: [
				{ limit: 1, orderBy: [{ publishedAt: OrderDirection.desc }] },
				{
					link: [
						{},
						{
							url: true,
						},
					],
				},
			],
		})

		const redirectUrl = latestArticle.listArticle[0]?.link?.url

		if (redirectUrl) {
			throw new RespondWithRedirect({ destination: redirectUrl, permanent: false })
		}

		throw new Error('Article not found')
	}

	if (data.getLinkable?.linkItem?.link) {
		const redirectHeader =
			data.getLinkable.linkItem.link.internalLink?.url ??
			data.getLinkable.linkItem.link.externalLink

		if (redirectHeader) {
			throw new RespondWithRedirect({ destination: redirectHeader, permanent: false })
		}

		throw new Error('Url not found')
	}

	const articles = await content.query({
		paginateArticle: [
			{
				orderBy: [{ publishedAt: OrderDirection.desc }],
				first: perPage,
				skip: (page - 1) * perPage,
			},
			{
				pageInfo: {
					totalCount: true,
				},
				edges: {
					node: ArticleFragment(),
				},
			},
		],
	})

	const paginatedArticles = normalizePaginated(page, perPage, articles.paginateArticle)

	let nextPageUrl: string | null = null

	if (data.getLinkable?.article) {
		const nextArticle = await content.query({
			listArticle: [
				{
					limit: 1,
					orderBy: [{ publishedAt: OrderDirection.desc }],
					filter: {
						publishedAt: {
							lt: data.getLinkable.article.publishedAt,
						},
					},
				},
				{
					link: [
						{},
						{
							url: true,
						},
					],
				},
			],
		})

		nextPageUrl = nextArticle.listArticle[0]?.link?.url ?? null
	}

	if (!data.getLinkable?.homePage && !data.getLinkable?.article && !data.getLinkable?.articlePage) {
		throw new RespondWithNotFound('Blog post not found')
	}

	return {
		props: {
			url,
			data,
			urlInfo,
			serverTime: new Date().toString(),
			paginatedArticles,
			nextPageUrl,
		},
	}
})
