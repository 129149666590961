import { RichTextRenderer } from '@contember/react-client'
import cn from 'clsx'
import React from 'react'
import type { ContentBlockReference, ContentResult } from '../../data/content/ContentFragment'
import { Link } from '../Link'
import s from './BlockRenderer.module.sass'
import * as referenceRenderersSource from './referenceRenderers'

// adapt React components with capitalized name to blocks names
const referenceRenderers = Object.fromEntries(
	Object.entries(referenceRenderersSource).map(([k, v]) => [
		k.charAt(0).toLowerCase() + k.slice(1),
		v,
	])
)

export function BlockWrapper(props: { textual?: boolean; children: React.ReactNode }) {
	return (
		<div className={cn(s.Block, props.textual && s.Textual)}>
			<div className={s.In}>{props.children}</div>
		</div>
	)
}

const elementRenderers = {
	internalAnchor(props: {
		children: React.ReactNode
		reference?: { id: string } & Partial<ContentBlockReference>
	}) {
		if (props.reference?.link) {
			return <Link link={props.reference.link}>{props.children}</Link>
		}
		return <span>{props.children}</span>
	},
}

export function BlockRenderer(props: {
	className?: string
	inClassName?: string
	blocks: ContentResult['blocks']
	sourceField?: string
	wrappedBlocks?: string[]
	textualWrapper?: React.ComponentType<{ children: React.ReactNode; textual?: boolean }>
	blockWrappers?: Record<string, React.ComponentType<{ children: React.ReactNode }> | undefined>
}) {
	;(global as unknown as { __DEV_MODE__: boolean }).__DEV_MODE__ =
		process.env.NODE_ENV === 'development'

	const blocks = React.useMemo(() => {
		const allReferences: ContentResult['blocks'][number]['references'] = []

		return props.blocks.map((block) => {
			allReferences.push(...block.references)

			return { ...block, references: allReferences }
		})
	}, [props.blocks])

	return (
		<div className={cn(s.Content, props.className)}>
			<div className={s.ContentIn}>
				<RichTextRenderer
					blocks={blocks}
					sourceField={props.sourceField ?? 'json'}
					referenceRenderers={referenceRenderers}
					renderBlock={(props: { block: unknown; children?: React.ReactNode }) => {
						console.log('renderBlock', props)
						return <BlockWrapper>{props.children}</BlockWrapper>
					}}
					renderLeaf={(props) => {
						return props.fallback
					}}
					renderElement={(el) => {
						if (el.element.type in elementRenderers) {
							return elementRenderers[el.element.type as keyof typeof elementRenderers](el)
						}

						return el.fallback
					}}
				/>
			</div>
		</div>
	)
}
